import ApiERP from '@/core/conectores/ApiERP';
import { ICidade } from '@/models/Entidades/Cadastros/Localizacoes/ICidade';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IDTOCidade } from '@/models/DTO/Cadastros/Localizacoes/IDTOCidade';
import storeSistema from '@/store/storeSistema';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

/**
 * Serviço de Cidades
 * Fornece todas regras de negócios e lógicas relacionado a cidades.
 */

class ServicoCidade implements IServicoBase<ICidade> {
    endPoint = 'cidades';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IDTOCidade): string {
      return objeto.cidade;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida, apresentarEstado?: boolean): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      let parametroApresentarEstado = '';
      if (UtilitarioGeral.objetoValido(apresentarEstado)) {
        parametroApresentarEstado = `&apresentarEstado=${apresentarEstado}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}${parametroApresentarEstado}`);
      return result.data;
    }

    public async obterCidadeConsultaRapida(codigoCidade: number, apresentarEstado:boolean, empresas?: number[]): Promise<IItemConsultaRapida> {
      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        valor: codigoCidade.toString(), filtrarPorCodigo: true, recursoAssociado: '',
      };

      if (UtilitarioGeral.validaLista(empresas)) {
        parametrosConsultaRapida.empresas = empresas;
      } else {
        parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
      }

      const listaCidades = await this.consultaRapida(parametrosConsultaRapida, apresentarEstado);
      if (UtilitarioGeral.validaLista(listaCidades)) {
        return listaCidades[0];
      }

      return {} as IItemConsultaRapida;
    }

    public async incluir(cidade: ICidade): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/empresa/${storeSistema.getters.codigoEmpresaOperacao()}`, cidade);
      return result.data;
    }

    public async alterar(cidade: ICidade): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${cidade.codigo}/empresa/${storeSistema.getters.codigoEmpresaOperacao()}`, cidade);
      return result.data;
    }

    public async obter(codigo: number): Promise<ICidade> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${storeSistema.getters.codigoEmpresaOperacao()}`);
      return result.data;
    }

    public async obterCidadePorNomeUf(nomeCidade: string, uf: string): Promise<IDTOCidade> {
      const result: any = await this.apiERP.get(`${this.endPoint}/nome/${nomeCidade}/uf/${uf}`);
      return result.data;
    }

    public async obterCidadeDetalhada(codigo: number): Promise<IDTOCidade> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/detalhes`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}/empresa/${storeSistema.getters.codigoEmpresaOperacao()}`);
      return result.data;
    }
}
export default ServicoCidade;
