export interface IColumn {
    title?: string;
    dataIndex?: string;
    key: string;
    position: number;
    visible: boolean;
    ellipsis?: boolean;
    ordering?: boolean;
    customRenderRow?: number;
    fixed?: string;
    width?: number;
    align?: string;
}

export enum ECustomRenderRow {
    LinkAcao = 1,
    TagAtivo = 2,
    TagAtiva = 3,
    IconeAcoes = 4,
    HTMLPersonalizado = 5,
    MascararCPFCNPJ = 6,
    MascararTelefoneCelular = 7,
    MascararData = 8,
    MascararFracionado = 9,
}
