import { ICondicaoFiltroGenerico } from './ICondicaoFiltroGenerico';
import { ECondicao } from '../Enumeradores/ECondicao';
import { ETipoDado } from '../Enumeradores/ETipoDado';
import { IFiltroGenericoAdicionado } from './IFiltroGenericoAdicionado';
import { IPropriedadeConsulta } from '../Consulta/PropriedadeConsulta';
import { IItem } from '../AntDesign/IItem';

export interface IBuscaAvancada {
    filtros: Array<IPropriedadeConsulta>;
    filtroSelecionado: undefined;
    tipoDadoFiltroSelecionado: number;
    ordenacao: Array<IPropriedadeConsulta>;
    condicoes: Array<ICondicaoFiltroGenerico>;
    condicaoSelecionada: undefined;
    valoresSelecionados: Array<IItem>;
    valorSelecionado: IItem;
    valores: string[];
    primeiroValor: any;
    segundoValor: any;
    filtrosAdicionados: Array<IFiltroGenericoAdicionado>;
    chaveFiltrosAdicionados: number;
    pesquisaRealizadaComFiltrosAdicionados: boolean;
}

const listaPadraoCondicoes: ICondicaoFiltroGenerico[] = [
  { codigo: ECondicao.Contem, descricao: 'Contém', tipoDados: [ETipoDado.Texto] },
  { codigo: ECondicao.Inicia, descricao: 'Inicia com', tipoDados: [ETipoDado.Texto] },
  { codigo: ECondicao.Termina, descricao: 'Termina com', tipoDados: [ETipoDado.Texto] },
  { codigo: ECondicao.Igual, descricao: 'Igual a', tipoDados: [ETipoDado.Texto, ETipoDado.Inteiro, ETipoDado.Longo, ETipoDado.Numerico, ETipoDado.Data, ETipoDado.DataHoraMinuto, ETipoDado.DataHoraMinutoSegundo, ETipoDado.VerdadeiroFalso] },
  { codigo: ECondicao.Diferente, descricao: 'Diferente de', tipoDados: [ETipoDado.Texto, ETipoDado.Inteiro, ETipoDado.Longo, ETipoDado.Numerico, ETipoDado.Data, ETipoDado.DataHoraMinuto, ETipoDado.DataHoraMinutoSegundo, ETipoDado.VerdadeiroFalso] },
  { codigo: ECondicao.Menor, descricao: 'Menor que', tipoDados: [ETipoDado.Inteiro, ETipoDado.Longo, ETipoDado.Numerico, ETipoDado.Data, ETipoDado.DataHoraMinuto, ETipoDado.DataHoraMinutoSegundo] },
  { codigo: ECondicao.Maior, descricao: 'Maior que', tipoDados: [ETipoDado.Inteiro, ETipoDado.Longo, ETipoDado.Numerico, ETipoDado.Data, ETipoDado.DataHoraMinuto, ETipoDado.DataHoraMinutoSegundo] },
  { codigo: ECondicao.MenorOuIgual, descricao: 'Menor ou igual que', tipoDados: [ETipoDado.Inteiro, ETipoDado.Longo, ETipoDado.Numerico, ETipoDado.Data, ETipoDado.DataHoraMinuto, ETipoDado.DataHoraMinutoSegundo] },
  { codigo: ECondicao.MaiorOuIgual, descricao: 'Maior ou igual que', tipoDados: [ETipoDado.Inteiro, ETipoDado.Longo, ETipoDado.Numerico, ETipoDado.Data, ETipoDado.DataHoraMinuto, ETipoDado.DataHoraMinutoSegundo] },
  { codigo: ECondicao.Entre, descricao: 'Entre', tipoDados: [ETipoDado.Inteiro, ETipoDado.Longo, ETipoDado.Numerico, ETipoDado.Data, ETipoDado.DataHoraMinuto, ETipoDado.DataHoraMinutoSegundo] },
  { codigo: ECondicao.EstaEntre, descricao: 'Qualquer um desses', tipoDados: [ETipoDado.Inteiro, ETipoDado.Longo, ETipoDado.Numerico, ETipoDado.Texto] },
  { codigo: ECondicao.NaoEstaEntre, descricao: 'Nenhum desses', tipoDados: [ETipoDado.Inteiro, ETipoDado.Longo, ETipoDado.Numerico, ETipoDado.Texto] },
];

export default listaPadraoCondicoes;
