export default class UtilitarioDispositivo {
  static cameraDisponivel():boolean {
    let possuiCamera = false;
    try {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(() => {
          possuiCamera = true;
        });

      possuiCamera = true;
    } catch (error) {
      possuiCamera = false;
    }
    return possuiCamera;
  }

  static microfoneDisponivel():boolean {
    let possuiMicrofone = false;
    try {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(() => {
          possuiMicrofone = true;
        });
    } catch (error) {
      possuiMicrofone = false;
    }
    return possuiMicrofone;
  }

  static larguraTelaMobile():boolean {
    if (window.innerWidth <= 1080) {
      return true;
    }
    return false;
  }

  static larguraTelaResponsiva(larguraDesktop: number):number {
    if (window.innerWidth < 900) { return (window.innerWidth - 60); }

    return larguraDesktop;
  }
}
