
import {
  defineComponent, computed, reactive, onBeforeMount,
} from 'vue';
import { message } from 'ant-design-vue';
import dayjs from 'dayjs';
import listaPadraoCondicoes, { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IFiltroGenericoAdicionado } from '@/core/models/BuscaAvancada/IFiltroGenericoAdicionado';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { ECondicao } from '@/core/models/Enumeradores/ECondicao';
import { EOperadorLogico } from '@/core/models/Enumeradores/EOperadorLogico';
import { IItem } from '@/core/models/AntDesign/IItem';
import { ETipoDado } from '@/core/models/Enumeradores/ETipoDado';
import { IOptionString } from '@/core/models/AntDesign/IOption';
import ServicoCompartilhado from '@/servicos/ServicoCompartilhado';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';

const resultadoConsulta: any[] = [];

export enum ETipoFiltro{
    Padrao = 1,
    RecursoAssociado = 2,
    ListaItens = 3
}

export default defineComponent({
  name: 'BuscaAvancada',
  components: {
    MensagemSemDados,
  },
  props: {
    empresas: {
      type: Array as () => number[],
      required: true,
    },
    buscaAvancada: {
      type: Object as () => IBuscaAvancada,
      required: true,
    },
  },
  setup(props, { emit }) {
    const servicoCompartilhado = new ServicoCompartilhado();
    const dados = reactive({
      mobile: false,
      resultadoConsulta,
      exibirBuscaAvancada: false,
      tipoFiltro: {} as ETipoFiltro,
      recursoAssociado: '',
      listaItens: [] as IOptionString[],
      formatoData: '',
      formatoDataValor: '',
      exibirHoras: false,
      fetching: false,
      debounce: 0,
      computedBuscaAvancada: computed({
        get: () => props.buscaAvancada,
        set: (val) => {
          emit('update:buscaAvancada', val);
        },
      }),
    });

    dados.tipoFiltro = ETipoFiltro.Padrao;

    function validarInformacaoCampo(evento:any, informacao: any) {
      if (dados.computedBuscaAvancada.tipoDadoFiltroSelecionado === ETipoDado.Longo) {
        if (!/[0-9]/.test(informacao)) {
          evento.preventDefault();
        }
      } else if (dados.computedBuscaAvancada.tipoDadoFiltroSelecionado === ETipoDado.Numerico) {
        if (!/^[\d,.?!]+$/.test(informacao)) {
          evento.preventDefault();
        }
      }
    }

    function validarInformacaoDigitada(evento:any) {
      const charCode = String.fromCharCode(evento.keyCode);
      validarInformacaoCampo(evento, charCode);
    }

    function validarInformacaoColada(evento:any) {
      const informacao = evento.clipboardData.getData('text');
      validarInformacaoCampo(evento, informacao);
    }

    function obtenhaFormatoData(): string {
      let formatoData = '';
      switch (dados.computedBuscaAvancada.tipoDadoFiltroSelecionado) {
        case ETipoDado.DataHoraMinuto:
          formatoData = 'DD/MM/YYYY HH:mm';
          break;
        case ETipoDado.DataHoraMinutoSegundo:
          formatoData = 'DD/MM/YYYY HH:mm:ss';
          break;
        default:
          formatoData = 'DD/MM/YYYY';
          break;
      }
      return formatoData;
    }

    function obtenhaFormatoDataValor(): string {
      let formatoDataValor = '';
      switch (dados.computedBuscaAvancada.tipoDadoFiltroSelecionado) {
        case ETipoDado.DataHoraMinuto:
          formatoDataValor = 'YYYY-MM-DD HH:mm';
          break;
        case ETipoDado.DataHoraMinutoSegundo:
          formatoDataValor = 'YYYY-MM-DD HH:mm:ss';
          break;
        default:
          formatoDataValor = 'YYYY-MM-DD';
          break;
      }
      return formatoDataValor;
    }

    async function selecionarVariosItens(value: any, itensCompletos: any) {
      dados.computedBuscaAvancada.valoresSelecionados = itensCompletos;
    }

    async function selecionaUnicoItem(value: any, itemCompleto: any) {
      dados.computedBuscaAvancada.valorSelecionado = itemCompleto;
    }

    async function pesquisaAutoCompletar(value: any) {
      dados.resultadoConsulta = [];
      clearTimeout(dados.debounce);
      dados.debounce = setTimeout(async () => {
        const parametrosConsultaRapida: IParametrosConsultaRapida = {
          valor: value, recursoAssociado: dados.recursoAssociado, filtrarPorCodigo: false, apenasAtivos: false,
        };
        parametrosConsultaRapida.empresas = props.empresas;
        const dadosAPI = await servicoCompartilhado.consultaRapida(parametrosConsultaRapida);
        dados.resultadoConsulta = dadosAPI.map((item) => ({
          label: item.textoIdentificacao,
          value: item.codigo,
        }));
      }, 600);
    }

    function atualizaDefinicoesData() {
      dados.computedBuscaAvancada.primeiroValor = undefined;
      dados.computedBuscaAvancada.segundoValor = undefined;
      dados.formatoData = obtenhaFormatoData();
      dados.formatoDataValor = obtenhaFormatoDataValor();
    }

    function filtroAlterado(identificador: string) {
      const filtroUtilizado = props.buscaAvancada.filtros.find((filtro) => filtro.identificador === identificador);
      dados.tipoFiltro = ETipoFiltro.Padrao;
      dados.recursoAssociado = '';
      dados.listaItens = [];
      dados.resultadoConsulta = [];
      dados.computedBuscaAvancada.primeiroValor = undefined;
      dados.computedBuscaAvancada.segundoValor = undefined;
      dados.computedBuscaAvancada.valores = [];
      dados.computedBuscaAvancada.condicoes = [];
      if (filtroUtilizado !== undefined) {
        dados.computedBuscaAvancada.tipoDadoFiltroSelecionado = filtroUtilizado.tipoDado;
        switch (filtroUtilizado.tipoDado) {
          case ETipoDado.VerdadeiroFalso:
            dados.computedBuscaAvancada.primeiroValor = false;
            break;

          case ETipoDado.Data:
            dados.exibirHoras = false;
            atualizaDefinicoesData();
            break;
          case ETipoDado.DataHoraMinuto:
          case ETipoDado.DataHoraMinutoSegundo:
            dados.exibirHoras = true;
            atualizaDefinicoesData();
            break;
          default:
            break;
        }
        dados.computedBuscaAvancada.condicoes = listaPadraoCondicoes.filter((condicao) => condicao.tipoDados.some((tipoDdado) => tipoDdado === filtroUtilizado.tipoDado));
        if (filtroUtilizado.recursoAssociado !== '' && filtroUtilizado.recursoAssociado !== null) {
          dados.tipoFiltro = ETipoFiltro.RecursoAssociado;
        } else if (UtilitarioGeral.validaLista(filtroUtilizado.listaItens)) {
          dados.tipoFiltro = ETipoFiltro.ListaItens;
        }

        if (dados.tipoFiltro === ETipoFiltro.RecursoAssociado || dados.tipoFiltro === ETipoFiltro.ListaItens) {
          dados.computedBuscaAvancada.condicoes = dados.computedBuscaAvancada.condicoes.filter((condicao) => condicao.codigo === ECondicao.Igual || condicao.codigo === ECondicao.Diferente || condicao.codigo === ECondicao.EstaEntre || condicao.codigo === ECondicao.NaoEstaEntre);
        }
        dados.computedBuscaAvancada.condicaoSelecionada = undefined;
        dados.recursoAssociado = filtroUtilizado.recursoAssociado;
        if (dados.tipoFiltro === ETipoFiltro.ListaItens) {
          dados.listaItens = filtroUtilizado.listaItens.map((item) => ({
            label: item.descricao,
            value: item.identificador,
          }));
        }
      }
    }

    function validarFiltro(): boolean {
      if (props.buscaAvancada.filtroSelecionado === undefined) {
        message.warning('Selecione um filtro para ser adicionado!');
        return false;
      }

      if (props.buscaAvancada.condicaoSelecionada === undefined) {
        message.warning('Selecione uma condição para o filtro!');
        return false;
      }

      if (props.buscaAvancada.condicaoSelecionada === ECondicao.Entre) {
        if (props.buscaAvancada.primeiroValor === undefined || props.buscaAvancada.primeiroValor === '') {
          message.warning('Informe o primeiro valor do filtro selecionado!');
          return false;
        }

        if (props.buscaAvancada.segundoValor === undefined || props.buscaAvancada.segundoValor === '') {
          message.warning('Informe o segundo valor do filtro selecionado!');
          return false;
        }
      } else if (props.buscaAvancada.condicaoSelecionada === ECondicao.EstaEntre || props.buscaAvancada.condicaoSelecionada === ECondicao.NaoEstaEntre) {
        if (props.buscaAvancada.valores === undefined || props.buscaAvancada.valores.length === 0) {
          message.warning('É necessário informar pelo menos um valor para adicionar o filtro!');
          return false;
        }
      } else if (props.buscaAvancada.primeiroValor === undefined || props.buscaAvancada.primeiroValor === '') {
        message.warning('Informe um valor para adicionar o filtro!');
        return false;
      }
      return true;
    }

    function chaveFiltroAdicionado(): number {
      dados.computedBuscaAvancada.chaveFiltrosAdicionados += 1;
      return props.buscaAvancada.chaveFiltrosAdicionados;
    }

    function obtenhaDescricaoCondicao(codigoCondicao: number): string {
      const condicaoInformada = listaPadraoCondicoes.find((condicao) => condicao.codigo === codigoCondicao);
      if (condicaoInformada !== undefined) {
        return condicaoInformada.descricao.toLowerCase();
      }
      return '';
    }

    function obtenhaTextoOperador(codigoOperador: number): string {
      if (codigoOperador === EOperadorLogico.E) { return 'E'; }
      if (codigoOperador === EOperadorLogico.OU) { return 'OU'; }
      return '';
    }

    function obtenhaTextoVerdadeiroFalso(valor: any): string {
      if (valor === false) { return 'Desmarcado'; }
      if (valor) { return 'Marcado'; }
      return '';
    }

    function condicaoComDoisValores(): boolean {
      if (dados.computedBuscaAvancada.condicaoSelecionada === ECondicao.Entre) {
        return true;
      }
      return false;
    }

    function condicaoComListaValores(): boolean {
      if (dados.computedBuscaAvancada.condicaoSelecionada === ECondicao.EstaEntre || dados.computedBuscaAvancada.condicaoSelecionada === ECondicao.NaoEstaEntre) {
        return true;
      }
      return false;
    }

    function tipoDadoFiltroNumerico(): boolean {
      if (dados.computedBuscaAvancada.tipoDadoFiltroSelecionado === ETipoDado.Longo
          || dados.computedBuscaAvancada.tipoDadoFiltroSelecionado === ETipoDado.Numerico) {
        return true;
      }
      return false;
    }

    function condicaoComValorUnico(): boolean {
      if (condicaoComDoisValores()) return false;
      if (condicaoComListaValores()) return false;

      return true;
    }

    function tipoDadoFiltroData(): boolean {
      if (dados.computedBuscaAvancada.tipoDadoFiltroSelecionado === ETipoDado.Data
          || dados.computedBuscaAvancada.tipoDadoFiltroSelecionado === ETipoDado.DataHoraMinuto
          || dados.computedBuscaAvancada.tipoDadoFiltroSelecionado === ETipoDado.DataHoraMinutoSegundo) {
        return true;
      }
      return false;
    }

    function atualizarOperador(codigo:number) {
      const objIndex = dados.computedBuscaAvancada.filtrosAdicionados.findIndex(((filtro) => filtro.codigo === codigo));
      const filtroAdicionado = dados.computedBuscaAvancada.filtrosAdicionados[objIndex].filtro;
      filtroAdicionado.operador = filtroAdicionado.operador === EOperadorLogico.E ? EOperadorLogico.OU : EOperadorLogico.E;
      emit('aplicarFiltros');
    }

    function adicionarFiltro() {
      if (validarFiltro()) {
        let filtroUtilizado = props.buscaAvancada.filtros.find((filtro) => filtro.identificador === props.buscaAvancada.filtroSelecionado);
        if (filtroUtilizado === undefined) { filtroUtilizado = {} as IPropriedadeConsulta; }

        const novoFiltro = {} as IFiltroGenericoAdicionado;
        novoFiltro.codigo = chaveFiltroAdicionado();
        novoFiltro.filtro = {} as IFiltroGenerico;
        novoFiltro.filtro.identificador = filtroUtilizado.identificador;
        novoFiltro.apresentacao = filtroUtilizado.apresentacao;
        novoFiltro.filtro.tipoDado = filtroUtilizado.tipoDado;
        novoFiltro.filtro.operador = EOperadorLogico.E;
        novoFiltro.filtro.apresentacao = filtroUtilizado.apresentacao;
        novoFiltro.filtro.condicao = Number(props.buscaAvancada.condicaoSelecionada);
        novoFiltro.filtro.valores = [];

        if (condicaoComDoisValores()) {
          novoFiltro.filtro.valores.push(String(props.buscaAvancada.primeiroValor));
          novoFiltro.filtro.valores.push(String(props.buscaAvancada.segundoValor));
          if (dados.tipoFiltro === ETipoFiltro.RecursoAssociado || dados.tipoFiltro === ETipoFiltro.ListaItens) {
            if (filtroUtilizado.tipoDado === ETipoDado.Data || filtroUtilizado.tipoDado === ETipoDado.DataHoraMinuto || filtroUtilizado.tipoDado === ETipoDado.DataHoraMinutoSegundo) {
              novoFiltro.detalhes = `${obtenhaDescricaoCondicao(novoFiltro.filtro.condicao)}: ${dayjs(props.buscaAvancada.primeiroValor).format(obtenhaFormatoData())} e ${dayjs(props.buscaAvancada.segundoValor).format(obtenhaFormatoData())}`;
            } else {
              novoFiltro.detalhes = `${obtenhaDescricaoCondicao(novoFiltro.filtro.condicao)}: ${props.buscaAvancada.primeiroValor} e ${props.buscaAvancada.segundoValor}`;
            }
          }
        } else if (condicaoComListaValores()) {
          let textoValores = '';

          if (dados.tipoFiltro === ETipoFiltro.RecursoAssociado || dados.tipoFiltro === ETipoFiltro.ListaItens) {
            props.buscaAvancada.valoresSelecionados.forEach((item) => {
              if (textoValores !== '') { textoValores += ', '; }
              textoValores += item.label;
              novoFiltro.filtro.valores.push(String(item.value));
            });
          } else {
            novoFiltro.filtro.valores = props.buscaAvancada.valores;
            props.buscaAvancada.valores.forEach((item) => {
              if (textoValores !== '') { textoValores += ', '; }
              textoValores += item;
            });
          }

          novoFiltro.detalhes = `${obtenhaDescricaoCondicao(novoFiltro.filtro.condicao)}: ${textoValores}`;
        } else {
          if (dados.tipoFiltro === ETipoFiltro.RecursoAssociado || dados.tipoFiltro === ETipoFiltro.ListaItens) {
            novoFiltro.detalhes = `${obtenhaDescricaoCondicao(novoFiltro.filtro.condicao)}: ${props.buscaAvancada.valorSelecionado.label}`;
          } else if (filtroUtilizado.tipoDado === ETipoDado.VerdadeiroFalso) {
            novoFiltro.detalhes = `${obtenhaDescricaoCondicao(novoFiltro.filtro.condicao)}: ${obtenhaTextoVerdadeiroFalso(props.buscaAvancada.primeiroValor)}`;
          } else if (filtroUtilizado.tipoDado === ETipoDado.Data || filtroUtilizado.tipoDado === ETipoDado.DataHoraMinuto || filtroUtilizado.tipoDado === ETipoDado.DataHoraMinutoSegundo) {
            novoFiltro.detalhes = `${obtenhaDescricaoCondicao(novoFiltro.filtro.condicao)}: ${dayjs(props.buscaAvancada.primeiroValor).format(obtenhaFormatoData())}`;
          } else {
            novoFiltro.detalhes = `${obtenhaDescricaoCondicao(novoFiltro.filtro.condicao)}: ${props.buscaAvancada.primeiroValor}`;
          }
          novoFiltro.filtro.valores.push(String(props.buscaAvancada.primeiroValor));
        }

        dados.computedBuscaAvancada.filtrosAdicionados.push(novoFiltro);
        dados.computedBuscaAvancada.pesquisaRealizadaComFiltrosAdicionados = true;
        dados.computedBuscaAvancada.valores = [];
        dados.computedBuscaAvancada.valoresSelecionados = [];
        dados.computedBuscaAvancada.valorSelecionado = {} as IItem;
        dados.tipoFiltro = ETipoFiltro.Padrao;
        dados.recursoAssociado = '';
        dados.listaItens = [];
        dados.computedBuscaAvancada.primeiroValor = undefined;
        dados.computedBuscaAvancada.segundoValor = undefined;
        dados.computedBuscaAvancada.filtroSelecionado = undefined;
        dados.computedBuscaAvancada.condicaoSelecionada = undefined;
        dados.computedBuscaAvancada.condicoes = [];
        emit('aplicarFiltros');
      }
    }

    function removerFiltro(codigoFiltro: number) {
      let filtroUtilizado = props.buscaAvancada.filtrosAdicionados.find((filtro) => filtro.codigo === codigoFiltro);
      if (filtroUtilizado === undefined) { filtroUtilizado = {} as IFiltroGenericoAdicionado; }
      const indice = props.buscaAvancada.filtrosAdicionados.indexOf(filtroUtilizado);
      dados.computedBuscaAvancada.filtrosAdicionados.splice(indice, 1);
      emit('aplicarFiltros');
    }

    function limparFiltros() {
      dados.computedBuscaAvancada.filtrosAdicionados = [];
      dados.computedBuscaAvancada.pesquisaRealizadaComFiltrosAdicionados = false;
      emit('aplicarFiltros');
    }

    onBeforeMount(async () => {
      dados.mobile = UtilitarioDispositivo.larguraTelaMobile();
    });

    function desabilitarCamposAteSelecionarFiltro():boolean {
      return (dados.computedBuscaAvancada.filtroSelecionado === undefined);
    }

    return {
      dados,
      ETipoDado,
      ETipoFiltro,
      filtroAlterado,
      validarFiltro,
      adicionarFiltro,
      removerFiltro,
      limparFiltros,
      pesquisaAutoCompletar,
      condicaoComValorUnico,
      condicaoComDoisValores,
      condicaoComListaValores,
      selecionaUnicoItem,
      selecionarVariosItens,
      atualizarOperador,
      obtenhaTextoOperador,
      tipoDadoFiltroData,
      tipoDadoFiltroNumerico,
      validarInformacaoDigitada,
      validarInformacaoColada,
      desabilitarCamposAteSelecionarFiltro,
      UtilitarioGeral,
    };
  },
});
