import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { ITipoContato } from '@/models/Entidades/Cadastros/Pessoas/Contatos/ITipoContato';

/**
 * Serviço de Tipos de contatos
 * Fornece todas regras de negócios e lógicas relacionado a tipos de contatos.
 */

class ServicoTipoContato implements IServicoBase<ITipoContato> {
    endPoint = 'tipos-contatos';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async obterTodos(empresas: number[], relacoesPessoa?: number[]): Promise<ITipoContato[]> {
      let parametrosTipoContato = '';
      if (empresas !== undefined && empresas.length > 0) {
        empresas.forEach((codigoEmpresa) => {
          parametrosTipoContato += (parametrosTipoContato !== '' ? '&' : '?');
          parametrosTipoContato += `Empresas=${codigoEmpresa}`;
        });
      }

      if (relacoesPessoa !== undefined && relacoesPessoa.length > 0) {
        relacoesPessoa.forEach((tipoRelacao) => {
          parametrosTipoContato += (parametrosTipoContato !== '' ? '&' : '?');
          parametrosTipoContato += `Relacoes=${tipoRelacao}`;
        });
      }

      const result: any = await this.apiERP.get(`${this.endPoint}/todos${parametrosTipoContato}`);
      return result.data;
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: ITipoContato): string {
      return objeto.descricao;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
      const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async incluir(tipoContato: ITipoContato): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, tipoContato);
      return result.data;
    }

    public async alterar(tipoContato: ITipoContato): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${tipoContato.codigo}`, tipoContato);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<ITipoContato> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }
}
export default ServicoTipoContato;
