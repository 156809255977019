
import { defineComponent } from 'vue';
import { Empty } from 'ant-design-vue';

export default defineComponent({
  name: 'MensagemSemDados',
  props: {
    mensagem: {
      type: String,
      default: 'Nenhum dado foi adicionada ainda',
    },
    mensagemAdicional: {
      type: String,
      default: '',
    },
    resultadoPesquisa: {
      type: Boolean,
      default: false,
    },
    adicionarDado: {
      type: Boolean,
      default: false,
    },
    tamanhoImagem: {
      type: Number,
      default: 200,
    },
  },
  setup(props) {
    function obterImagem():string {
      if (props.resultadoPesquisa) { return '/static/img/ilustracoes/resultado-pesquisa.png'; }

      if (props.adicionarDado) { return '/static/img/ilustracoes/novo-registro.png'; }

      return '/static/img/ilustracoes/sem-registro.png';
    }
    return {
      props,
      Empty,
      obterImagem,
    };
  },
});
