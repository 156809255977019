
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BarraProgresso',
  props: {
    visivel: {
      type: Boolean,
      required: true,
    },
    frase: {
      type: String,
      default: 'Aguarde por favor, estamos processando a sua solicitação...',
    },
  },
  setup(props) {
    return {
      props,
    };
  },
});
