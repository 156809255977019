/**
 * Serviço ServicoCompartilhado
 * Centraliza e organiza regras essências para efetuar a consulta rápida do sistema
 */

import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import ServicoCidade from './Cadastros/Localizacoes/ServicoCidade';
import ServicoEstado from './Cadastros/Localizacoes/ServicoEstado';
import ServicoPais from './Cadastros/Localizacoes/ServicoPais';
import ServicoDepartamentoColaborador from './Cadastros/Pessoas/ServicoDepartamentoColaborador';
import ServicoFuncaoColaborador from './Cadastros/Pessoas/ServicoFuncaoColaborador';
import ServicoTipoContato from './Cadastros/Pessoas/ServicoTipoContato';
import ServicoCategoriaProduto from './Cadastros/Produtos/ServicoCategoriaProduto';
import ServicoMarca from './Cadastros/Produtos/ServicoMarca';
import ServicoNcm from './Cadastros/Produtos/ServicoNcm';
import ServicoUnidade from './Cadastros/Produtos/ServicoUnidade';
import ServicoUsuario from './MeuSistema/ServicoUsuario';
import ServicoCategoriaPermissao from './PainelAdministrativo/Permissoes/ServicoCategoriaPermissao';
import ServicoPermissao from './PainelAdministrativo/Permissoes/ServicoPermissao';
import ServicoModulo from './PainelAdministrativo/ServicoModulo';

class ServicoCompartilhado {
  public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
    let retornoConsulta:IItemConsultaRapida[] = [];

    switch (parametrosConsultaRapida.recursoAssociado) {
      case 'CADASTRO_PAISES':
        retornoConsulta = await new ServicoPais().consultaRapida(parametrosConsultaRapida);
        break;
      case 'CADASTRO_ESTADOS':
        retornoConsulta = await new ServicoEstado().consultaRapida(parametrosConsultaRapida);
        break;
      case 'CADASTRO_CIDADES':
        retornoConsulta = await new ServicoCidade().consultaRapida(parametrosConsultaRapida, true);
        break;
      case 'CADASTRO_CATEGORIAS_PERMISSAO':
        retornoConsulta = await new ServicoCategoriaPermissao().consultaRapida(parametrosConsultaRapida);
        break;
      case 'CADASTRO_MODULOS':
        retornoConsulta = await new ServicoModulo().consultaRapida(parametrosConsultaRapida);
        break;
      case 'CADASTRO_PERMISSOES':
        retornoConsulta = await new ServicoPermissao().consultaRapida(parametrosConsultaRapida);
        break;

      case 'CADASTRO_TIPOS_CONTATOS':
        retornoConsulta = await new ServicoTipoContato().consultaRapida(parametrosConsultaRapida);
        break;

      case 'CADASTRO_FUNCOES_COLABORADORES':
        retornoConsulta = await new ServicoFuncaoColaborador().consultaRapida(parametrosConsultaRapida);
        break;

      case 'CADASTRO_DEPARTAMENTOS_COLABORADORES':
        retornoConsulta = await new ServicoDepartamentoColaborador().consultaRapida(parametrosConsultaRapida);
        break;

      case 'CADASTRO_USUARIOS':
        retornoConsulta = await new ServicoUsuario().consultaRapida(parametrosConsultaRapida, true);
        break;

      case 'CADASTRO_CATEGORIAS_PRODUTOS':
        retornoConsulta = await new ServicoCategoriaProduto().consultaRapida(parametrosConsultaRapida);
        break;

      case 'CADASTRO_MARCAS':
        retornoConsulta = await new ServicoMarca().consultaRapida(parametrosConsultaRapida);
        break;

      case 'CADASTRO_NCMS':
        retornoConsulta = await new ServicoNcm().consultaRapida(parametrosConsultaRapida);
        break;

      case 'CADASTRO_UNIDADES':
        retornoConsulta = await new ServicoUnidade().consultaRapida(parametrosConsultaRapida);
        break;
      default:
        break;
    }
    return retornoConsulta;
  }
}

export default ServicoCompartilhado;
