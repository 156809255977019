import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IUnidade } from '@/models/Entidades/Cadastros/Produtos/IUnidade';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IDTOUnidadeProdutoMovimento } from '@/models/DTO/Cadastros/Produtos/IDTOUnidadeProdutoMovimento';

/**
 * Serviço de Unidades
 * Fornece todas regras de negócios e lógicas relacionado a Unidades.
 */

class ServicoUnidade implements IServicoBase<IUnidade> {
    endPoint = 'unidades';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: IUnidade): string {
      return objeto.descricao;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida, apresentarApenasSigla?:boolean): Promise<IItemConsultaRapida[]> {
      let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      if (UtilitarioGeral.validaBoleano(apresentarApenasSigla)) {
        parametrosAdicionais += `&apresentarApenasSigla=${apresentarApenasSigla}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async obterUnidadesProdutoMovimento(codigoProdutoDefinicao: number, apenasUnidadePrincipal: boolean, valorCodigosProduto?: string): Promise<IDTOUnidadeProdutoMovimento[]> {
      let parametrosAdicionais = '';
      if (UtilitarioGeral.validaBoleano(apenasUnidadePrincipal)) {
        parametrosAdicionais += `?apresentarApenasSigla=${apenasUnidadePrincipal}`;
      }

      if (UtilitarioGeral.valorValido(valorCodigosProduto)) {
        parametrosAdicionais += `&valorCodigosProduto=${valorCodigosProduto}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/produto/${codigoProdutoDefinicao}${parametrosAdicionais}`);
      return result.data;
    }

    public async incluir(unidade: IUnidade): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}`, unidade);
      return result.data;
    }

    public async alterar(unidade: IUnidade): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/${unidade.codigo}`, unidade);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<IUnidade> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async verificaSeUnidadePossuiMovimentacao(codigo: number, empresas: number[]): Promise<boolean> {
      const parametrosAdicionais = this.apiERP.obterParametroEmpresas(empresas);
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/verificar/movimentacao${parametrosAdicionais}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
      return result.data;
    }

    public async obterTodasUnidades(empresas: number[]): Promise<IUnidade[]> {
      const parametrosAdicionais = this.apiERP.obterParametroEmpresas(empresas);
      const result: any = await this.apiERP.get(`${this.endPoint}/todas${parametrosAdicionais}`);
      return result.data;
    }
}
export default ServicoUnidade;
