import ApiERP from '@/core/conectores/ApiERP';
import { IUsuario, IUsuarioPerfilUsuario } from '@/models/Entidades/MeuSistema/Usuarios/IUsuario';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import storeSistema from '@/store/storeSistema';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoArquivo } from '@/core/models/IRetornoArquivo';
import { IPermissaoUsuario } from '@/models/Entidades/MeuSistema/Usuarios/Permissoes/IPermissaoUsuario';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IParametrosConsultaAgrupadaPerfil } from '@/models/DTO/MeuSistema/Usuarios/IParametrosConsultaAgrupadaPerfil';
import { IDTOUsuarioAgrupadoPerfil } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioAgrupadoPerfil';
import { IDTOControlePermissoes } from '@/models/DTO/MeuSistema/Usuarios/IDTOControlePermissoes';
/**
 * Serviço de Usuários
 * Fornece todas regras de negócios e lógicas relacionado aos Usuários.
 */

class ServicoUsuario implements IServicoBase<IUsuario> {
  endPoint = 'usuarios';

  apiERP = new ApiERP();

  public requisicaoSistema():void {
    this.apiERP = new ApiERP(true);
  }

  public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
    const listaTeste = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
    return listaTeste;
  }

  public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida, apresentarCodigo?: boolean): Promise<IItemConsultaRapida[]> {
    const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
    let parametroApresentarEstado = '';
    if (UtilitarioGeral.objetoValido(apresentarCodigo)) {
      parametroApresentarEstado = `&apresentarCodigo=${apresentarCodigo}`;
    }
    const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}${parametroApresentarEstado}`);
    return result.data;
  }

  public async consultaRapidaPorCodigos(codigos:number[], empresas:number[], apresentarCodigo?: boolean): Promise<IItemConsultaRapida[]> {
    const parametrosConsultaRapida:IParametrosConsultaRapida = {} as IParametrosConsultaRapida;
    parametrosConsultaRapida.filtrarPorVariosCodigos = true;
    parametrosConsultaRapida.empresas = empresas;
    parametrosConsultaRapida.valores = [];

    codigos.forEach((codigoUsuario) => {
      if (parametrosConsultaRapida.valores !== undefined) {
        parametrosConsultaRapida.valores.push(codigoUsuario.toString());
      }
    });

    const result = await this.consultaRapida(parametrosConsultaRapida, apresentarCodigo);
    return result;
  }

  public async obterUsuariosPorCodigosConsultaRapida(codigos: number[]): Promise<IItemConsultaRapida[]> {
    const parametrosConsultaRapida: IParametrosConsultaRapida = {
      valor: '',
      recursoAssociado: '',
      filtrarPorVariosCodigos: true,
      quantidadeRegistros: 99999,
    };
    parametrosConsultaRapida.valores = [];
    codigos.forEach((codigo) => {
      if (parametrosConsultaRapida.valores !== undefined) {
        parametrosConsultaRapida.valores.push(codigo.toString());
      }
    });

    return this.apiERP.consultaRapida(this.endPoint, parametrosConsultaRapida);
  }

  public async consultaAgrupadaPorPerfil(parametrosConsulta: IParametrosConsultaAgrupadaPerfil): Promise<IDTOUsuarioAgrupadoPerfil[]> {
    let parametrosAdicionais = '';
    parametrosAdicionais = this.apiERP.obterParametroEmpresas(parametrosConsulta.empresas);

    if (parametrosConsulta.apenasAtivos !== undefined) {
      parametrosAdicionais += `&ApenasAtivos=${parametrosConsulta.apenasAtivos}`;
    }
    if (parametrosConsulta.ignorarUsuarioAutenticado !== undefined) {
      parametrosAdicionais += `&IgnorarUsuarioAutenticado=${parametrosConsulta.ignorarUsuarioAutenticado}`;
    }

    if (parametrosConsulta.nomeUsuario !== undefined && parametrosConsulta.nomeUsuario !== '') {
      parametrosAdicionais += `&NomeUsuario=${parametrosConsulta.nomeUsuario}`;
    }

    if (parametrosConsulta.perfis !== undefined && parametrosConsulta.perfis.length > 0) {
      parametrosConsulta.perfis.forEach((codigoPerfil) => {
        parametrosAdicionais += `&Perfis=${codigoPerfil}`;
      });
    }
    const result: any = await this.apiERP.get(`${this.endPoint}/consulta-agrupada-perfil${parametrosAdicionais}`);
    return result.data;
  }

  public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
    return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
  }

  public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
    return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
  }

  public obterIdentificacaoRegistroGradeCRUD(objeto: IUsuario): string {
    return objeto.nome;
  }

  public async incluir(usuario: IUsuario): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}`, usuario);
    return result.data;
  }

  public async alterar(usuario: IUsuario): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/${usuario.codigo}`, usuario);
    return result.data;
  }

  public async atualizarPermissoesUsuario(usuario: number, empresa: number, permissoes: IPermissaoUsuario []): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/${usuario}/empresa/${empresa}/permissoes`, permissoes);
    return result.data;
  }

  public async atualizarPerfilUsuarioComPermissoes(usuario: number, empresa: number, perfil:number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.patch(`${this.endPoint}/${usuario}/empresa/${empresa}/perfil/${perfil}`);
    return result.data;
  }

  public async obter(codigo: number): Promise<IUsuario> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async obterPerfisUsuarios(codigo: number, empresa: number): Promise<IUsuarioPerfilUsuario[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}/perfis`);
    return result.data;
  }

  public async excluir(codigo: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async obterPermissoesControle(codigo: number, empresa: number): Promise<IDTOControlePermissoes[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}/permissoes/controle`);
    return result.data;
  }

  public async enviarImagem(arquivo: any, atualizarUsuario: boolean): Promise<IRetornoArquivo> {
    const data = new FormData();
    data.append('arquivo', arquivo);
    const result: any = await this.apiERP.upload(`${this.endPoint}/imagem/${storeSistema.getters.codigoUsuarioAutenticado()}/empresa/${storeSistema.getters.codigoEmpresaOperacao()}/atualizar-usuario/${atualizarUsuario}`, data);
    return result.data;
  }

  public async removerImagem(linkArquivo: string): Promise<IRetornoArquivo> {
    const result: any = await this.apiERP.delete(`${this.endPoint}/${storeSistema.getters.codigoUsuarioAutenticado()}/empresa/${storeSistema.getters.codigoEmpresaOperacao()}/imagem?linkArquivo=${linkArquivo}`);
    return result.data;
  }

  public async validarControlePermissoes(usuario: number, empresa: number): Promise<boolean> {
    const result: any = await this.apiERP.get(`${this.endPoint}/validar-controle-permissoes/${usuario}/empresa/${empresa}`);
    return result.data;
  }
}
export default ServicoUsuario;
