
import {
  defineComponent, computed, reactive, watch,
} from 'vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import BarraProgresso from '@/core/components/Tela/BarraProgresso.vue';

export default defineComponent({
  name: 'RequisicaoModal',
  components: {
    BarraProgresso,
  },
  props: {
    visivel: {
      type: Boolean,
      required: true,
      default: false,
    },
    titulo: {
      type: String,
    },
    apresentarBarraProgresso: {
      type: Boolean,
      default: false,
    },
    fraseBarraProgresso: {
      type: String,
      default: '',
    },
    retornoRequisicao: {
      type: Object as () => IRetornoRequisicao,
      required: true,
    },
  },
  setup(props, { emit }) {
    const dados = reactive({
      arquivoImagem: '/static/img/ilustracoes/alerta.gif',
      computedVisivel: computed({
        get: () => props.visivel,
        set: (val) => {
          emit('update:visivel', val);
        },
      }),
      computedTitulo: computed({
        get: () => props.titulo,
        set: (val) => {
          emit('update:titulo', val);
        },
      }),
      computedRetornoRequisicao: computed({
        get: () => props.retornoRequisicao,
        set: (val) => {
          emit('update:retornoRequisicao', val);
        },
      }),
    });

    function fecharModal() {
      dados.computedVisivel = false;
    }

    watch(dados.computedRetornoRequisicao, (retornoRequisicao) => {
      if (retornoRequisicao.status === EStatusRetornoRequisicao.Alerta) {
        dados.arquivoImagem = '/static/img/ilustracoes/alerta.gif';
      } else if (retornoRequisicao.status === EStatusRetornoRequisicao.Sucesso) {
        dados.arquivoImagem = '/static/img/ilustracoes/sucesso.gif';
      } else if (retornoRequisicao.status === EStatusRetornoRequisicao.Erro) {
        dados.arquivoImagem = '/static/img/ilustracoes/erro.gif';
      }
    });

    return {
      props,
      dados,
      fecharModal,
    };
  },
});
