export enum ETipoDado {
    Inteiro = 1,
    Longo = 2,
    Numerico = 3,
    Texto = 4,
    VerdadeiroFalso = 5,
    Data = 6,
    DataHoraMinuto = 7,
    DataHoraMinutoSegundo = 8,
}
