export enum ECondicao {
    Contem = 1,
    Inicia = 2,
    Termina = 3,
    Igual = 4,
    Diferente = 5,
    Menor = 6,
    Maior = 7,
    MenorOuIgual = 8,
    MaiorOuIgual = 9,
    Entre = 10,
    EstaEntre = 11,
    NaoEstaEntre = 12
}
