import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_empty = _resolveComponent("a-empty")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_empty, {
      image: _ctx.obterImagem()
    }, {
      description: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.props.mensagem), 1),
        (_ctx.props.mensagemAdicional)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.props.mensagemAdicional), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["image"])
  ]))
}